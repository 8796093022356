import React, { useState } from 'react';
import useGlobal from "../../store";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
    REQUISITIONS, REQUISITION_INDICATORS
  } from "./gql/Queries";
import RequisitionsAdminView from '../../Views/ProcessRelationship/RequisitionsAdminView';
import moment from "moment";
import { ARCHIVE_MULTIPLE_REQUISITION_CONFIG, ARCHIVE_REQUISITION_CONFIG } from './gql/Mutations';

const RequisitionsAdmin = ({ match, location }) => {
  const processRelationshipId = match?.params?.id;
  const processRelationship = location?.state?.processRelationship;

  const [showArchivedRequisitions, setShowArchivedRequisitions] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const [session] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const [filters, setFilters] = useState({
    processRelationshipId: processRelationshipId,
    app: session.user ? session.appRef._id : "",
    //expirationDate_gte: moment(new Date()).startOf("day").format(),
    userId: session.user ? session.user._id : "",
    archived: showArchivedRequisitions
  });

  const [status, setStatus] = useState("All");

  const {
    data: dataRequisitionsIndicator,
    loading: loadingRequisitionsIndicator,
  } = useQuery(REQUISITION_INDICATORS, {
    variables: {
      where: filters,
    },
    fetchPolicy: "network-only",
  });


  const {
    data: dataRequisitions,
    loading: loadingRequisitions,
    error: errorRequisitions,
    refetch,
  } = useQuery(REQUISITIONS, {
    variables: {
      where: filters,
    },
    fetchPolicy: "network-only",
  });

  
  const [updateMultipleRequisition] = useMutation(ARCHIVE_MULTIPLE_REQUISITION_CONFIG, {
    onCompleted: (mutationData) => {
      console.log("mutationData", mutationData);
      refetch();
    },
    onError: (error) => {
        console.log(error)
    }
  });
  
  const changeMultipleRequisitionState = (requisitionArray, archived) => {

    const variables = {
      where: {
        _ids_in: requisitionArray.map((req) => req._id),
        app: session.appRef._id,
        processRelationshipId: processRelationshipId,
        archived: archived
      },
    }

    updateMultipleRequisition({
      variables: variables
    })
    setCheckedItems({});
  };

  return (
    <RequisitionsAdminView
      indicator={dataRequisitionsIndicator?.getRequisitionIndicator ?? {}}
      requisitions={{ loading: loadingRequisitions, data: dataRequisitions?.getRequisitionConfig ?? [] }}
      processRelationshipId={processRelationshipId}
      processRelationship={processRelationship}
      filters={filters}
      setFilters={setFilters}
      status={status}
      setStatus={setStatus}
      changeMultipleRequisitionStatus={changeMultipleRequisitionState}
      showArchivedRequisitions={showArchivedRequisitions}
      setShowArchivedRequisitions={setShowArchivedRequisitions}
      checkedItems={checkedItems}
      setCheckedItems={setCheckedItems}
    />
  );
}

export default RequisitionsAdmin;
