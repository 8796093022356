import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { onDebounce$ } from '../../utils/subject-rxjs';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Table,
  } from "@material-ui/core";

const debounceHalfSecond$ = onDebounce$(500);

const ArchiveRequisitionConfigModal = ({ ...props }) => {
    const {
        open,
        handleModalClose,
        okCallback,
        requisition,
    } = props;

    const handleSave = () => {
        okCallback();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleModalClose}>
                <DialogTitle>
                    Archivar
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <strong>
                            Se archivarán los elementos, que aun sigan vigentes. ¿Desea proceder?
                        </strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">Cancelar</Button>
                    <Button onClick={handleSave} color="secondary">Archivar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ArchiveRequisitionConfigModal.propTypes = {
    toggleModal: PropTypes.bool,
    okCallback: PropTypes.func
};

ArchiveRequisitionConfigModal.defaultProps = {};

//export default withStyles(styles)(ArchiveRequisitionConfigModal);
export default ArchiveRequisitionConfigModal;
