import React, { useState } from 'react';
import ReactTable from "react-table";
import { Checkbox, Typography } from '@material-ui/core';
import DivCenter from "../../components/styled-components/DivCenter";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useGlobal from '../../store';
import auth from '../../utils/auth';
import { ArchiveOutlined, Delete, RestoreFromTrash } from '@material-ui/icons';
import ArchiveRequisitionConfigModal from '../../Views/ProcessRelationship/ArchiveRequisitionConfigModal';

const RequisitionsAdminTable = ({
  data,
  processRelationship,
  filters,
  setFilters,
  status,
  setStatus,
  showArchivedRequisitions,
  checkedItems,
  setCheckedItems,
  confirmMultipleArchiveAction
}) => {

  const [session] = useGlobal((state) => state.session);

  const hasPermission = (permission) => {
    if (session.user && !auth.hasPermission(permission, session.permissions)) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChange = (event, item) => {
    setCheckedItems({
      ...checkedItems,
      [item._id]: event.target.checked
    });
  };
  
  const addItemToArchive = (item, state) => {
    setCheckedItems(prevState => {
      const newState = { 
        ...prevState, 
        [item._id]: state 
      };

      confirmMultipleArchiveAction(newState);
      return newState;
    });
  };

  return (
    <ReactTable
      data={data}
      columns={[
        {
          Header: (
            <DivCenter style={{ ...styles.headerTitle }}>Requisición</DivCenter>
          ),
          accessor: "name",
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "break-spaces" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {original.name}
              </Typography>
            );
          },
        },
        {
          Header: <DivCenter style={styles.headerTitle}>Progreso</DivCenter>,
          accessor: "progress",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            const { processActivities } = original;
            return (
              <Typography style={styles.contentText}>
                {(processActivities?.elementProcess ?? 0).toFixed(2)} %
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>
              Elementos Pendientes
            </DivCenter>
          ),
          accessor: "totalPenginDates",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            const { processActivities } = original;
            return (
              <Typography style={styles.contentText}>
                {processActivities?.process ?? 0}
              </Typography>
            );
          },
        },
        {
          Header: <DivCenter style={styles.headerTitle}>Descripción</DivCenter>,
          accessor: "description",
          filterable: false,
          sortable: false,
          style: { textAlign: "center", whiteSpace: "break-spaces" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {original.description}
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>Fecha Limite</DivCenter>
          ),
          accessor: "expirationDate",
          sortable: false,
          filterable: true,
          Filter: () => (
            <Select
              style={{ width: "100%" }}
              value={status}
              onChange={(e) => {
                
                const defaultParams = {
                  processRelationshipId: filters.processRelationshipId,
                  app: filters.app,
                }

                const expiredOpion = {
                  'All' : () =>  ({ 
                    ...defaultParams
                   }),
                  'Valid' : () => ({ 
                    ...defaultParams,
                    expirationDate_gte: moment(new Date()).format(),
                   }),
                  'Expired' : () => ({ 
                    ...defaultParams,
                    expirationDate_lte: moment(new Date()).format(),
                   })
                }

                setFilters(expiredOpion[e.target.value]);
                setStatus(e.target.value);
              }}
            >
              <MenuItem value={"All"} key={"All"}>
                Todas
              </MenuItem>
              <MenuItem value={"Valid"} key={"Valid"}>
                Vigentes
              </MenuItem>

              <MenuItem value={"Expired"} key={"Expired"}>
                Vencidas
              </MenuItem>
            </Select>
          ),
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {moment(original.expirationDate).format("DD-MM-YYYY")}
              </Typography>
            );
          },
        },
        {
          Header: (
            <DivCenter style={styles.headerTitle}>Area que lo emite</DivCenter>
          ),
          accessor: "areaName",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <Typography style={styles.contentText}>
                {original.areaName}
              </Typography>
            );
          },
        },
        {
          Header: <DivCenter style={styles.headerTitle}></DivCenter>,
          accessor: "action",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <div>
                <Link
                  to={{
                    pathname: `/objects/${original.processRelationshipId}/requisition/view/${original._id}`,
                    state: {
                      element: original,
                      processRelationship: processRelationship,
                    },
                  }}
                >
                  <Button justIcon round simple color="info">
                    <Tooltip title={"Ver"}>
                      <VisibilityIcon style={{ fontSize: 40 }} />
                    </Tooltip>
                  </Button>
                </Link>
                {hasPermission("requisitionEdit") ? (
                  showArchivedRequisitions ? 
                  (
                    <Button justIcon round simple color="info" disabled>
                      <EditIcon style={{ fontSize: 40 }} />
                    </Button>
                  ) :
                  (
                    <Link
                    to={{
                      pathname: `/objects/${original.processRelationshipId}/requisition/edit/${original._id}`,
                      state: {
                        requisition: original,
                      },
                    }}
                    >
                      <Button justIcon round simple color="info">
                        <Tooltip title={"Editar"}>
                          <EditIcon style={{ fontSize: 40 }} />
                        </Tooltip>
                      </Button>
                    </Link>
                  )
                ) : (
                  ""
                )}
                {hasPermission("requisitionEdit") ? (
                  <Button justIcon round simple color="info" onClick={() => {
                      addItemToArchive(original, true);
                    }}>
                    <Tooltip title={showArchivedRequisitions ? "Activar" : "Archivar"}>
                      {showArchivedRequisitions ? <RestoreFromTrash style={{ fontSize: 40 }}></RestoreFromTrash> : <Delete style={{ fontSize: 40 }} /> }
                    </Tooltip>
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          },
        },
        {
          Header: <DivCenter style={styles.headerTitle}></DivCenter>,
          accessor: "action",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: ({ original }) => {
            return (
              <div>
                {hasPermission("requisitionEdit") ? (
                    <div key={original._id}>
                      <Checkbox
                        checked={checkedItems[original._id] || false}
                        onChange={(event) => handleCheckboxChange(event, original)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}/>
                    </div>
                ) : (
                  ""
                )}
              </div>
            );
          },
        },
      ]}
      defaultPageSize={data.length}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      resizable={false}
    />
  );
};

const styles = {
    headerTitle: {
      fontSize: "20px",
      whiteSpace: 'break-spaces',
      fontWeight: "500", 
    },
    contentText: {
      fontSize: 18,
    },
    
  };

export default RequisitionsAdminTable;
