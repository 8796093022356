import gql from "graphql-tag";

export const CREATE_PROCESS_RELATIONSHIP = gql`
  mutation createProcessRelationship($data: ProcessRelationshipCreateInput!) {
    createProcessRelationship(data: $data) {
      _id
      name
    }
  }
`;

export const UPDATE_PROCESS_RELATIONSHIP = gql`
  mutation updateProcessRelationship(
    $where: ProcessRelationshipWhereUniqueInput!
    $data: ProcessRelationshipCreateInput!
  ) {
    updateProcessRelationship(where: $where, data: $data) {
      _id
      name
    }
  }
`;

export const ARCHIVE_PROCESS_RELATIONSHIP = gql`
  mutation archiveProcessRelationship(
    $where: ProcessRelationshipWhereUniqueInput!
  ) {
    archiveProcessRelationship(where: $where) {
      _id
      name
    }
  }
`;

export const CREATE_CHECKLIST_REPORT = gql`
  mutation createProcessRelationship($data: ProcessRelationshipCreateInput!) {
    createProcessRelationship(data: $data) {
      _id
      name
    }
  }
`;

export const UPDATE_CHECKLIST_REPORT = gql`
  mutation updateProcessRelationship(
    $where: ProcessRelationshipWhereUniqueInput!
    $data: ProcessRelationshipCreateInput!
  ) {
    updateProcessRelationship(where: $where, data: $data) {
      _id
      name
    }
  }
`;

export const ARCHIVE_CHECKLIST_REPORT = gql`
  mutation archiveProcessRelationship(
    $where: ProcessRelationshipWhereUniqueInput!
  ) {
    archiveProcessRelationship(where: $where) {
      _id
      name
    }
  }
`;


export const CREATE_WORK_PLAN_PENALTY = gql`
  mutation createWorkPlanPenalty(
    $data: WorkPlanPenaltyInput
    $where: WorkPlanPenaltyWhereInput
  ) {
    createWorkPlanPenalty(data: $data, where: $where) {
      _id
      name
      description
      elementsType
      elementsList
      visits
      operationPonderable
      periodicity
      visitWithNA
      assignedUserInfo {
        label
        relations {
          userId
          elementId
          tags
        }
      }
      visitPonderation {
        visitId
        ponderation
      }
      workPlanConfig {
        tableFields {
          visitId
          moduleId
          key
          activityName
        }
        checklistFields {
          visitId
          moduleId
          key
          activityName
        }
        solutionModuleId
        penaltiesConfig {
          _id
          indicator
          operation
          value
          percentagePenalty
        }
      }
    }
  }
`;

export const UPDATE_WORK_PLAN_PENALTY = gql`
  mutation updateWorkPlanPenalty(
    $data: WorkPlanPenaltyInput
    $where: WorkPlanPenaltyWhereInput
  ) {
    updateWorkPlanPenalty(data: $data, where: $where) {
      _id
      name
      description
      elementsType
      elementsList
      visits
      operationPonderable
      periodicity
      visitWithNA
      assignedUserInfo {
        label
        relations {
          userId
          elementId
          tags
        }
      }
      visitPonderation {
        visitId
        ponderation
      }
      workPlanConfig {
        tableFields {
          visitId
          moduleId
          key
          activityName
        }
        checklistFields {
          visitId
          moduleId
          key
          activityName
        }
        solutionModuleId
        penaltiesConfig {
          _id
          indicator
          operation
          value
          percentagePenalty
        }
      }
    }
  }
`;

export const DELETE_WORK_PLAN_PENALTY = gql`
  mutation deleteWorkPlanPenalty(
    $data: WorkPlanPenaltyInput
    $where: WorkPlanPenaltyWhereInput
  ) {
    deleteWorkPlanPenalty(data: $data, where: $where) {
      _id
      name
      description
      elementsType
      elementsList
      visits
      operationPonderable
      periodicity
      visitWithNA
      assignedUserInfo {
        label
        relations {
          userId
          elementId
          tags
        }
      }
      visitPonderation {
        visitId
        ponderation
      }
      workPlanConfig {
        tableFields {
          visitId
          moduleId
          key
          activityName
        }
        checklistFields {
          visitId
          moduleId
          key
          activityName
        }
        solutionModuleId
        penaltiesConfig {
          _id
          indicator
          operation
          value
          percentagePenalty
        }
      }
    }
  }
`;

export const CREATE_REQUISITION_CONFIG = gql`
  mutation createRequisitionConfig(
    $data: RequisitionConfigInput!
    $images: [ImagesInput]
  ){
    createRequisition(data: $data, images: $images){
      _id
      processRelationshipId
      name
      description
      expirationDate
      elementsIds
      solutionModuleId
      screenId
      images {
        original
        i1280
        i240
        i320
        i480
        i640
        i960
      }
    }
  }
`;

export const UPDATE_REQUISITION_CONFIG = gql`
  mutation updateRequisition(
    $data: RequisitionConfigInput!
    $where: RequisitionConfigWhereUniqueInput
    $images: [ImagesInput]
  ){
    updateRequisition(data: $data, where: $where ,images: $images){
      _id
      processRelationshipId
      name
      description
      expirationDate
      elementsIds
      solutionModuleId
      screenId
      images {
        original
        i1280
        i240
        i320
        i480
        i640
        i960
      }
    }
  }
`;

export const ARCHIVE_REQUISITION_CONFIG = gql`
  mutation ArchiveRequisition($where: ArchiveRequisitionConfigInput){
    archiveRequisition(where: $where){
      name
      description
      archived
      processRelationshipId
      _id
    }
  }
`;

export const ARCHIVE_MULTIPLE_REQUISITION_CONFIG = gql`
  mutation ArchiveManyRequisitions($where: ArchiveRequisitionConfigInput){
    archiveManyRequisitions(where: $where){
      success
      message
    }
  }
`;

export const CREATE_REQUISITION_PENALTY = gql`
  mutation createPenaltyRequisition($data: RequisitionPenaltiesInput!) {
    createPenaltyRequisition(data: $data) {
      _id
      processRelationshipId
      requisitionId
      indicator
      operation
      value
      percentagePenalty
    }
  }
`;

export const UPDATE_REQUISITION_PENALTY = gql`
  mutation updatePenaltyRequisition($data: RequisitionPenaltiesInput! $where: RequisitionConfigWhereUniqueInput) {
    updatePenaltyRequisition(data: $data, where: $where) {
      _id
      processRelationshipId
      requisitionId
      indicator
      operation
      value
      percentagePenalty
    }
  }
`;

export const DELETE_REQUISITION_PENALTY = gql`
  mutation deleteRequisitionPenalty($where: RequisitionConfigWhereUniqueInput) {
    deleteRequisitionPenalty(where: $where)
  }
`;

export const UPDATE_REQUISITION_ACTIVITY = gql`
  mutation updateRequisitionActivity($data: RequisitionActivityInput, $where: RequisitionActivityWhereUniqueInput) {
    updateRequisitionActivity(data: $data, where: $where){
      _id
      app
      processRelationshipId
      requisitionId
      elementId
      elementName
      assignedUserId
      status
      expirationDate
      reportedDate
      approvedDate
    }
  }
`;

export const GENERATE_PROCESS_RELATIONSHIP_EMAIL_REPORT = gql`
  mutation generateProcessRelationshipReportPDF($where: ProcessRelationshipEmailPDFInput) {
    generateProcessRelationshipReportPDF(where: $where)
  }
`;

export const GET_KEY_FIELD_ANSWERS = gql`
  mutation KeyFieldsAnswers($where: VisitAnswersIndicatorsWhereInput) {
    keyFieldsAnswers(where: $where) {
      keyField
      answers
    }
  }
`;

export const GENERATE_REQUISITION_ACTIVITIES_REPORT_XSL = gql`
  mutation generateRequisitionActivitiesReportXLS($data: ChecklistRepordInput!){
    generateRequisitionActivitiesReportXLS(data: $data)
  }
`;